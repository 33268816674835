/* tslint:disable */
/* eslint-disable */
/**
 * Ampersand public API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PaginationInfo
 */
export interface PaginationInfo {
    /**
     * If set to true, this is the last page of results for the given operation. There are no more results & there will be no nextPageToken sent when done is true.
     * @type {boolean}
     * @memberof PaginationInfo
     */
    done: boolean;
    /**
     * If present, set this value against your 'pageToken' query parameter in the next API call, which will retrieve the next set of results.
     * @type {string}
     * @memberof PaginationInfo
     */
    nextPageToken?: string;
}

/**
 * Check if a given object implements the PaginationInfo interface.
 */
export function instanceOfPaginationInfo(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "done" in value;

    return isInstance;
}

export function PaginationInfoFromJSON(json: any): PaginationInfo {
    return PaginationInfoFromJSONTyped(json, false);
}

export function PaginationInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaginationInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'done': json['done'],
        'nextPageToken': !exists(json, 'nextPageToken') ? undefined : json['nextPageToken'],
    };
}

export function PaginationInfoToJSON(value?: PaginationInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'done': value.done,
        'nextPageToken': value.nextPageToken,
    };
}

