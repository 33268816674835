import { useCallback } from 'react';
import { Configuration } from '@generated/api/src';
import { useAuth } from 'src/auth';

import { ApiService } from './ApiService';

const prismURL = 'http://127.0.0.1:4010';

/**
  * We can modify the authentication, baseURL and other configurations to access
  * our API in the future.
  */
const API_URL = import.meta.env.VITE_AMP_API_URL === prismURL
  ? prismURL : `${import.meta.env.VITE_AMP_API_URL}/v1`;

export function useAPI(): () => Promise<ApiService> {
  const { getToken } = useAuth();

  const getAPI = useCallback(async () => {
    const token = await getToken();
    if (!token) {
      // eslint-disable-next-line no-console
      console.error('Unable to generate auth token for authenticated API request.');
    }
    const userConfig = new Configuration({
      basePath: API_URL,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return new ApiService(userConfig);
  }, [getToken]);

  return getAPI;
}

/**
 * Types exported from generated api
 */
export type {
  ApiKeyRequest,
  BuilderInfo,
  Builder, Invite,
  CreateDestinationRequest,
  Configuration, Installation,
  Destination,
  Integration, Operation, Log,
  ProviderApp, ProviderInfo,
  HydratedRevision, Project,
  Config,
  CreateProjectRequest,
  CreateOrgInviteRequest,
  HydratedIntegrationObject,
  UpdateApiKeyRequest,
  UpdateDestinationOperationRequest,
  UpdateInstallationOperationRequest,
  UpdateInstallationRequestInstallationConfig,
  UpdateProjectOperationRequest,
  UpdateProviderAppOperationRequest,
  CreateProviderAppOperationRequest,
} from '@generated/api/src';
export { ApiService } from './ApiService';
