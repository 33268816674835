/* tslint:disable */
/* eslint-disable */
/**
 * Ampersand public API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SubscribeOpts
 */
export interface SubscribeOpts {
    /**
     * The scope of the subscription.
     * @type {string}
     * @memberof SubscribeOpts
     */
    subscriptionScope: SubscribeOptsSubscriptionScopeEnum;
    /**
     * The scope of the target URL.
     * @type {string}
     * @memberof SubscribeOpts
     */
    targetURLScope: SubscribeOptsTargetURLScopeEnum;
    /**
     * The timing of the registration.
     * @type {string}
     * @memberof SubscribeOpts
     */
    registrationTiming: SubscribeOptsRegistrationTimingEnum;
}


/**
 * @export
 */
export const SubscribeOptsSubscriptionScopeEnum = {
    Integration: 'integration',
    Installation: 'installation'
} as const;
export type SubscribeOptsSubscriptionScopeEnum = typeof SubscribeOptsSubscriptionScopeEnum[keyof typeof SubscribeOptsSubscriptionScopeEnum];

/**
 * @export
 */
export const SubscribeOptsTargetURLScopeEnum = {
    Integration: 'integration',
    Installation: 'installation'
} as const;
export type SubscribeOptsTargetURLScopeEnum = typeof SubscribeOptsTargetURLScopeEnum[keyof typeof SubscribeOptsTargetURLScopeEnum];

/**
 * @export
 */
export const SubscribeOptsRegistrationTimingEnum = {
    ProviderApp: 'providerApp',
    Integration: 'integration',
    Installation: 'installation'
} as const;
export type SubscribeOptsRegistrationTimingEnum = typeof SubscribeOptsRegistrationTimingEnum[keyof typeof SubscribeOptsRegistrationTimingEnum];


/**
 * Check if a given object implements the SubscribeOpts interface.
 */
export function instanceOfSubscribeOpts(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "subscriptionScope" in value;
    isInstance = isInstance && "targetURLScope" in value;
    isInstance = isInstance && "registrationTiming" in value;

    return isInstance;
}

export function SubscribeOptsFromJSON(json: any): SubscribeOpts {
    return SubscribeOptsFromJSONTyped(json, false);
}

export function SubscribeOptsFromJSONTyped(json: any, ignoreDiscriminator: boolean): SubscribeOpts {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'subscriptionScope': json['subscriptionScope'],
        'targetURLScope': json['targetURLScope'],
        'registrationTiming': json['registrationTiming'],
    };
}

export function SubscribeOptsToJSON(value?: SubscribeOpts | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'subscriptionScope': value.subscriptionScope,
        'targetURLScope': value.targetURLScope,
        'registrationTiming': value.registrationTiming,
    };
}

