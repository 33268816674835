/* tslint:disable */
/* eslint-disable */
/**
 * Ampersand public API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    ValueDefaultBoolean,
    instanceOfValueDefaultBoolean,
    ValueDefaultBooleanFromJSON,
    ValueDefaultBooleanFromJSONTyped,
    ValueDefaultBooleanToJSON,
} from './ValueDefaultBoolean';
import {
    ValueDefaultInteger,
    instanceOfValueDefaultInteger,
    ValueDefaultIntegerFromJSON,
    ValueDefaultIntegerFromJSONTyped,
    ValueDefaultIntegerToJSON,
} from './ValueDefaultInteger';
import {
    ValueDefaultString,
    instanceOfValueDefaultString,
    ValueDefaultStringFromJSON,
    ValueDefaultStringFromJSONTyped,
    ValueDefaultStringToJSON,
} from './ValueDefaultString';

/**
 * @type ValueDefault
 * 
 * @export
 */
export type ValueDefault = ValueDefaultBoolean | ValueDefaultInteger | ValueDefaultString;

export function ValueDefaultFromJSON(json: any): ValueDefault {
    return ValueDefaultFromJSONTyped(json, false);
}

export function ValueDefaultFromJSONTyped(json: any, ignoreDiscriminator: boolean): ValueDefault {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return { ...ValueDefaultBooleanFromJSONTyped(json, true), ...ValueDefaultIntegerFromJSONTyped(json, true), ...ValueDefaultStringFromJSONTyped(json, true) };
}

export function ValueDefaultToJSON(value?: ValueDefault | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }

    if (instanceOfValueDefaultBoolean(value)) {
        return ValueDefaultBooleanToJSON(value as ValueDefaultBoolean);
    }
    if (instanceOfValueDefaultInteger(value)) {
        return ValueDefaultIntegerToJSON(value as ValueDefaultInteger);
    }
    if (instanceOfValueDefaultString(value)) {
        return ValueDefaultStringToJSON(value as ValueDefaultString);
    }

    return {};
}

