/* tslint:disable */
/* eslint-disable */
/**
 * Ampersand public API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ApiKeyOpts } from './ApiKeyOpts';
import {
    ApiKeyOptsFromJSON,
    ApiKeyOptsFromJSONTyped,
    ApiKeyOptsToJSON,
} from './ApiKeyOpts';
import type { AuthType } from './AuthType';
import {
    AuthTypeFromJSON,
    AuthTypeFromJSONTyped,
    AuthTypeToJSON,
} from './AuthType';
import type { BasicAuthOpts } from './BasicAuthOpts';
import {
    BasicAuthOptsFromJSON,
    BasicAuthOptsFromJSONTyped,
    BasicAuthOptsToJSON,
} from './BasicAuthOpts';
import type { Media } from './Media';
import {
    MediaFromJSON,
    MediaFromJSONTyped,
    MediaToJSON,
} from './Media';
import type { Oauth2Opts } from './Oauth2Opts';
import {
    Oauth2OptsFromJSON,
    Oauth2OptsFromJSONTyped,
    Oauth2OptsToJSON,
} from './Oauth2Opts';
import type { SubscribeOpts } from './SubscribeOpts';
import {
    SubscribeOptsFromJSON,
    SubscribeOptsFromJSONTyped,
    SubscribeOptsToJSON,
} from './SubscribeOpts';
import type { Support } from './Support';
import {
    SupportFromJSON,
    SupportFromJSONTyped,
    SupportToJSON,
} from './Support';

/**
 * 
 * @export
 * @interface ProviderInfo
 */
export interface ProviderInfo {
    /**
     * 
     * @type {string}
     * @memberof ProviderInfo
     */
    name: string;
    /**
     * 
     * @type {AuthType}
     * @memberof ProviderInfo
     */
    authType: AuthType;
    /**
     * The base URL for making API requests.
     * @type {string}
     * @memberof ProviderInfo
     */
    baseURL: string;
    /**
     * 
     * @type {Oauth2Opts}
     * @memberof ProviderInfo
     */
    oauth2Opts?: Oauth2Opts;
    /**
     * 
     * @type {ApiKeyOpts}
     * @memberof ProviderInfo
     */
    apiKeyOpts?: ApiKeyOpts;
    /**
     * 
     * @type {BasicAuthOpts}
     * @memberof ProviderInfo
     */
    basicOpts?: BasicAuthOpts;
    /**
     * 
     * @type {Support}
     * @memberof ProviderInfo
     */
    support: Support;
    /**
     * Additional provider-specific metadata.
     * @type {{ [key: string]: string; }}
     * @memberof ProviderInfo
     */
    providerOpts: { [key: string]: string; };
    /**
     * The display name of the provider, if omitted, defaults to provider name.
     * @type {string}
     * @memberof ProviderInfo
     */
    displayName?: string;
    /**
     * If true, we require additional information after auth to start making requests.
     * @type {boolean}
     * @memberof ProviderInfo
     */
    postAuthInfoNeeded?: boolean;
    /**
     * 
     * @type {Media}
     * @memberof ProviderInfo
     */
    media?: Media;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof ProviderInfo
     */
    labels?: { [key: string]: string; };
    /**
     * 
     * @type {SubscribeOpts}
     * @memberof ProviderInfo
     */
    subscribeOpts?: SubscribeOpts;
}

/**
 * Check if a given object implements the ProviderInfo interface.
 */
export function instanceOfProviderInfo(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "authType" in value;
    isInstance = isInstance && "baseURL" in value;
    isInstance = isInstance && "support" in value;
    isInstance = isInstance && "providerOpts" in value;

    return isInstance;
}

export function ProviderInfoFromJSON(json: any): ProviderInfo {
    return ProviderInfoFromJSONTyped(json, false);
}

export function ProviderInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProviderInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'authType': AuthTypeFromJSON(json['authType']),
        'baseURL': json['baseURL'],
        'oauth2Opts': !exists(json, 'oauth2Opts') ? undefined : Oauth2OptsFromJSON(json['oauth2Opts']),
        'apiKeyOpts': !exists(json, 'apiKeyOpts') ? undefined : ApiKeyOptsFromJSON(json['apiKeyOpts']),
        'basicOpts': !exists(json, 'basicOpts') ? undefined : BasicAuthOptsFromJSON(json['basicOpts']),
        'support': SupportFromJSON(json['support']),
        'providerOpts': json['providerOpts'],
        'displayName': !exists(json, 'displayName') ? undefined : json['displayName'],
        'postAuthInfoNeeded': !exists(json, 'postAuthInfoNeeded') ? undefined : json['postAuthInfoNeeded'],
        'media': !exists(json, 'media') ? undefined : MediaFromJSON(json['media']),
        'labels': !exists(json, 'labels') ? undefined : json['labels'],
        'subscribeOpts': !exists(json, 'subscribeOpts') ? undefined : SubscribeOptsFromJSON(json['subscribeOpts']),
    };
}

export function ProviderInfoToJSON(value?: ProviderInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'authType': AuthTypeToJSON(value.authType),
        'baseURL': value.baseURL,
        'oauth2Opts': Oauth2OptsToJSON(value.oauth2Opts),
        'apiKeyOpts': ApiKeyOptsToJSON(value.apiKeyOpts),
        'basicOpts': BasicAuthOptsToJSON(value.basicOpts),
        'support': SupportToJSON(value.support),
        'providerOpts': value.providerOpts,
        'displayName': value.displayName,
        'postAuthInfoNeeded': value.postAuthInfoNeeded,
        'media': MediaToJSON(value.media),
        'labels': value.labels,
        'subscribeOpts': SubscribeOptsToJSON(value.subscribeOpts),
    };
}

