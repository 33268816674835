import { CSSProperties, ReactNode } from 'react';
import classNames from 'classnames';

import { OverFlowCell } from './OverflowCell/OverflowCell';

import classes from './style.module.css';
/**
 *
 * Table Component matches html table element
 * and exports components in compound component pattern
 *
 * allows class and style overrides
 *
 * @returns
 */

interface TableProps {
  className?: string;
  style?: CSSProperties;
  children: ReactNode;
}

interface TableHeaderProps {
  className?: string;
  style?: CSSProperties;
  children: ReactNode;
}

interface TableBodyProps {
  className?: string;
  style?: CSSProperties;
  children: ReactNode;
}

interface TableRowProps {
  className?: string;
  style?: CSSProperties;
  children: ReactNode;
}

interface TableCellProps {
  className?: string;
  style?: CSSProperties;
  children?: ReactNode;
}

interface TableFooterProps {
  className?: string;
  style?: CSSProperties;
  children?: ReactNode;
}

const TableContainer: React.FC<TableProps> = ({ className, style, children }) => (
  <div className={classNames(classes.tableContainer, className)} style={style}>{children}</div>);

const Table: React.FC<TableProps> = ({ className, style, children }) => (
  <table className={className || classes.table} style={style}>
    {children}
  </table>
);

const TableHeader: React.FC<TableHeaderProps> = ({ className, style, children }) => (
  <thead className={classNames(classes.tableHeader, className)} style={style}>{children}</thead>);

const TableHeaderCell: React.FC<TableCellProps> = ({ className, style, children }) => (
  <th className={classNames(classes.tableHeaderCell, className)} style={style}>{children}</th>);

const TableBody: React.FC<TableBodyProps> = ({ className, style, children }) => (
  <tbody className={className} style={style}>{children}</tbody>);

const TableRow: React.FC<TableRowProps> = ({ className, style, children }) => (
  <tr className={classNames(classes.tableRow, className)} style={style}>{children}</tr>);

const TableCell: React.FC<TableCellProps> = ({ className, style, children }) => (
  <td className={classNames(classes.tableCell, className)} style={style}>{children}</td>);

const TableFooterDiv: React.FC<TableFooterProps> = ({ className, style, children }) => (
  <div className={classNames(classes.tableFooter, className)} style={style}>{children}</div>);

export const TableComponent = {
  TableContainer,
  Table,
  TableHeader,
  TableHeaderCell,
  TableBody,
  TableRow,
  TableCell,
  TableFooterDiv,
  OverFlowCell,
};
