/* tslint:disable */
/* eslint-disable */
/**
 * Ampersand public API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ValueDefault } from './ValueDefault';
import {
    ValueDefaultFromJSON,
    ValueDefaultFromJSONTyped,
    ValueDefaultToJSON,
} from './ValueDefault';

/**
 * 
 * @export
 * @interface BaseWriteConfigObject
 */
export interface BaseWriteConfigObject {
    /**
     * The name of the object to write to.
     * @type {string}
     * @memberof BaseWriteConfigObject
     */
    objectName?: string;
    /**
     * This is a map of field names to default values. These values will be used when writing to the object.
     * @type {{ [key: string]: ValueDefault; }}
     * @memberof BaseWriteConfigObject
     */
    selectedValueDefaults?: { [key: string]: ValueDefault; };
}

/**
 * Check if a given object implements the BaseWriteConfigObject interface.
 */
export function instanceOfBaseWriteConfigObject(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function BaseWriteConfigObjectFromJSON(json: any): BaseWriteConfigObject {
    return BaseWriteConfigObjectFromJSONTyped(json, false);
}

export function BaseWriteConfigObjectFromJSONTyped(json: any, ignoreDiscriminator: boolean): BaseWriteConfigObject {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'objectName': !exists(json, 'objectName') ? undefined : json['objectName'],
        'selectedValueDefaults': !exists(json, 'selectedValueDefaults') ? undefined : (mapValues(json['selectedValueDefaults'], ValueDefaultFromJSON)),
    };
}

export function BaseWriteConfigObjectToJSON(value?: BaseWriteConfigObject | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'objectName': value.objectName,
        'selectedValueDefaults': value.selectedValueDefaults === undefined ? undefined : (mapValues(value.selectedValueDefaults, ValueDefaultToJSON)),
    };
}

